import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
  }

  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #FF0000;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 50px;

  @media screen and (max-width: 960px) {
    padding: 0 40px;
  }
`;

export const MainHeading = styled.h1`
  font-family: 'Josefin Slab', serif;
  font-size: clamp(2rem, 8vw, 4.5rem);
  font-weight: 700;
  margin-bottom: 1.25rem;
  color: ${({ inverse }) => (inverse ? '#403ae3' : '#fff')};
  width: 100%;
  letter-spacing: 4px;
  text-align: left;
  text-shadow: 0 4px 4px #00000080;
`;

export const Heading = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-size: clamp(2.5rem, 16vw, 4rem);
  margin: ${({ margin }) => (margin ? margin : '')};
  margin-bottom: ${({ mb }) => (mb ? mb : '')};
  margin-top: ${({ mt }) => (mt ? mt : '')};
  color: ${({ inverse }) => (inverse ? '#202242' : '#fff')};
  /* letter-spacing: 0.4rem; */
  /* line-height: 1.06rem; */
  text-align: ${({ align }) => (align ? align : 'center')};
  width: ${({ width }) => (width ? width : '100%')};
`;

export const TextWrapper = styled.span`
  color: ${({ color }) => (color ? color : '')};
  /* font-family: 'Kite One', sans-serif; */
	font-size: ${({ size }) => (size ? size : '1rem')};
	font-weight: ${({ weight }) => (weight ? weight : '400')};
	letter-spacing: ${({ spacing }) => (spacing ? spacing : '')};
  line-height: ${({ height }) => (height ? height : '1.75rem')};
	padding: ${({ padding }) => (padding ? padding : '')};
	margin: ${({ margin }) => (margin ? margin : '')};
	margin-bottom: ${({ mb }) => (mb ? mb : '')};
	margin-top: ${({ mt }) => (mt ? mt : '')};
  text-align: ${({ align }) => (align ? align : 'center')};

  & > span {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  & > h2 {
    font-size: 2.75rem;
    line-height: 3rem;
    font-family: 'Kite One', sans-serif;
    color: #202242;
  }

  & > h3 {
    font-size: 1.75rem;
    font-family: 'Kite One', sans-serif;
    line-height: 3rem;
    color: #202242;
  }
`;

export const Section = styled.section`
  padding: ${({ padding }) => (padding ? padding : '140px 0')};
	margin: ${({ margin }) => (margin ? margin : '')};
	background: ${({ inverse, colour }) => (inverse ? 'white' : (colour ? colour : '#202242'))};
	color: ${({ inverse }) => (inverse ? '#000' : '#fff')};
	position: ${({ position }) => (position ? position : '')};
	width: ${({ width }) => (width ? width : 'auto')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};

	@media screen and (max-width: 768px) {
		padding: ${({ smPadding }) => (smPadding ? smPadding : '70px 0')};
	}

  @media screen and (max-width: 1024px) {
    height: ${({ mdHeight }) => (mdHeight ? mdHeight : 'auto')};
    min-height: ${({ mdMinHeight }) => (mdMinHeight ? mdMinHeight : 'auto')};
  }

  @media screen and (min-height: 1024px) {
    height: ${({ lgHeight }) => (lgHeight ? lgHeight : 'auto')};
  }
`;

export const Row = styled.div`
  display: flex;
	justify-content: ${({ justify }) => (justify ? justify : '')};
	align-items: ${({ align }) => (align ? align : '')};
	gap: ${({ gap }) => (gap ? gap : '')};
	padding: ${({ padding }) => (padding ? padding : '')};
	margin: ${({ margin }) => (margin ? margin : '')};
	position: ${({ position }) => (position ? position : '')};
	width: ${({ width }) => (width ? width : 'auto')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
	flex-wrap: ${({ wrap }) => (wrap ? wrap : '')};
`;

export const Column = styled.div`
  display: flex;
	flex-direction: column;
	justify-content: ${({ justify }) => (justify ? justify : '')};
	align-items: ${({ align }) => (align ? align : '')};
	gap: ${({ gap }) => (gap ? gap : '')};
	padding: ${({ padding }) => (padding ? padding : '')};
	margin: ${({ margin }) => (margin ? margin : '')};
	position: ${({ position }) => (position ? position : '')};
	width: ${({ width }) => (width ? width : 'auto')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
`;

export const Button = styled.button`
  border-radius: 4px;
  background: none;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  outline: none;
  border: 2px solid #fff;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:before {
    background: #fff;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
    width: 100%;
    height: 0%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover:before {
    height: 500%;
  }

  &:hover {
    color: black;
  }
`;

export default GlobalStyle;