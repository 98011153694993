import React, { useState } from 'react';
import './style.css';
import { Container, Section, Heading, TextWrapper } from '../../globalStyles';

import LayoutImage from '../_library/lay_img/script';

const Belief = () => {
  const [readMore, setReadMore] = useState(false);

  const linkName = readMore ? 'READ LESS ▲' : 'READ MORE ▼';
  return (
    <Section padding="0" smPadding="0" minHeight="fit-content" lgHeight="auto" position="relative" inverse id="belief">
      <div className="belief__banner">
        <Container>
          <div className='belief__banner_grid'>
            <div className="belief__heading">
              <Heading align="right" >What</Heading>
              <span>we believe</span>
            </div>
            <div className='belief__banner_img'>
              <img src="./assets/svg/cross.svg" alt="Banner: belief" />
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="belief__container">
          <div className="belief__grid">
            {/* <div className="belief__image">
              <LayoutImage img="./assets/belief-2.jpg" alt="Banner2: belief" width={400} />
            </div> */}
            <div className="belief__story">
              <TextWrapper align="left" >
                <h3>Comforter Ministries International upholds the truths of the historic Christian faith, including the following items of belief:</h3><br />
                <ul>
                  <li>There is one true God, infinite and all powerful, eternally existing in three persons, the Father, the Son and the Holy Spirit.</li><br />
                  <li>God is loving and merciful, holy and just and is the creator and sustainer of all things.</li><br />
                  <li>God has revealed himself in the Scriptures of the Old and New Testaments. All Scripture is uniquely inspired by God and is therefore wholly trustworthy and of supreme and final authority in faith and life.</li>
                </ul>
              </TextWrapper>

              <div className="extra__content">
                {readMore && extraContent}
                <div className="read-more-link">
                  <a onClick={() => { setReadMore(!readMore) }}><h2>{linkName}</h2></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default Belief;

export const extraContent = <div>
  <TextWrapper align="left" >
    <ul>
      <li>Jesus Christ is God’s only Son and God incarnate. He was conceived by the Holy Spirit, born of the virgin Mary and lived a perfect and sinless human life. He was sent to reveal God, to proclaim the reign of God in the power of the Holy Spirit, and to accomplish God’s saving purposes.</li><br />
      <li>Christ died on the cross for our sins as our representative and substitute. God raised him from the dead through the power of the Spirit and he is exalted at the right hand of the Father, having received all authority in heaven and on earth.</li>
      <li>Human beings are made in the image of God. They are created to love, worship and enjoy fellowship with God and have been entrusted with the stewardship of God’s creation.</li><br />
      <li>Through human disobedience to God, sin and death entered the world. All have sinned and are therefore guilty before God and under God’s judgement. Sin separates people from God; it also brings brokenness to human relationships, disrupts the created order and distorts humanity’s relationship with creation.</li><br />
      <li>Salvation is the gift of God’s grace through the death of Jesus Christ, and is available to all who repent of their sins and by faith receive him as Saviour and Lord. They are born again by the Holy Spirit, thereby becoming children of God.</li><br />
      <li>Through the Spirit’s indwelling, God’s people are enabled to live holy lives and are equipped, in the fellowship of the Church, to witness and work for Christ and to glorify God in the world.</li><br />
      <li>The Church is the community of all Christian believers, who have been formed by the Holy Spirit into one body of which the Lord Jesus Christ is the Head.</li><br />
      <li>The Church is commissioned by Christ to bear witness to the gospel to all peoples through word, deed and sign.
Christ will return in power and glory to consummate God’s kingdom. He will gather his people, judge the world in righteousness, and renew creation. Those outside of Christ will be raised to eternal judgement; those who belong to Christ will be raised to enjoy eternal life in God’s presence.</li><br />
    </ul>
  </TextWrapper>
</div>;