import React, { useState, useRef } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import { data } from '../../data/CarouselData';
import { Row, Heading, Section } from '../../globalStyles';
import { ButtonContainer, ImageWrapper, CarouselImage, ControlWrapper } from './CarouselStyles';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import { Autoplay, Scrollbar } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';

import './style.css';

const Carousel = () => {
	const [sliderRef, setSliderRef] = useState(null);

	return (
		<Section margin="auto" maxWidth="1280px" padding="50px 70px" id="gallery" inverse>
			<div className="carousel__container">
				<Row justify="space-between" margin="1rem" wrap="wrap">
					<Heading inverse>
						Gallery
					</Heading>
				</Row>

				<div className="swiper__container">
					<Swiper
						modules={[Autoplay, Scrollbar]}
						spaceBetween={40}
						// slidesPerView={3}
						centeredSlides={true}
						rewind={true}
						autoplay={{
							delay: 1500,
							disableOnInteraction: false,
						}}
						scrollbar={{ draggable: true }}
						breakpoints={{
							640: {
								slidesPerView: 1,
								spaceBetween: 20,
							},
							768: {
								slidesPerView: 2,
								spaceBetween: 30,
							},
							1024: {
								slidesPerView: 3,
								spaceBetween: 40,
							},
						}}
						onSwiper={(swiper) => setSliderRef(swiper)}
					// onSlideChange={() => console.log('slide change')}
					>

						{data.map((el, index) => (
							<SwiperSlide key={index}>
								<ImageWrapper>
									<CarouselImage src={el.image} />
								</ImageWrapper>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				<ControlWrapper>
					<ButtonContainer>
						<IconContext.Provider value={{ size: '2rem', color: '#202242' }}>
							<BiChevronLeft onClick={() => sliderRef?.slidePrev()} />
							<BiChevronRight onClick={() => sliderRef?.slideNext()} />
						</IconContext.Provider>
					</ButtonContainer>
				</ControlWrapper>
			</div>

		</Section>
	);
};

export default Carousel;