import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Container } from '../../globalStyles';

export const Nav = styled.nav`
  background: #20224280;
	margin-bottom: -80px;
	height: 128px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Inter', sans-serif;
	font-size: 1.25rem;
	font-weight: 300;
	position: absolute;
	top: 0;
	z-index: 50;
	width: 100%;

	transition: background-color 0.3s ease-in;

	@media screen and (max-width: 960px) {
		height: 96px;
	}
`;

export const NavbarContainer = styled(Container)`
  display: flex;
	justify-content: start;
	height: 128px;

	${Container}

	@media screen and (max-width: 960px) {
		height: 96px;
	}
`;

export const NavLogo = styled(Link)`
  color: #fff;
	justify-self: flex-start;
	cursor: pointer;
	text-decoration: none;
	font-family: 'Josefin Slab', serif;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	z-index: 50;

	@media screen and (max-width: 960px) {
		font-size: 1.25rem;
		max-width: clamp(20rem, 40%, 40rem);
	}
`;

export const NavIcon = styled.img`
  margin-right: 1rem;
	width: 3rem;
	height: auto;

	@media screen and (max-width: 960px) {
		width: 2.5rem;
	}
`;

export const MobileIcon = styled.div`
  display: none;
	z-index: 50;

	@media screen and (max-width: 960px) {
		display: block;
		position: absolute;
		top: 50%;
		right: 2rem;
		transform: translateY(-50%);
		font-size: 1.75rem;
		cursor: pointer;
	}
`;

export const NavMenu = styled.ul`
  display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	width: 100%;

	@media screen and (max-width: 960px) {
		flex-direction: column;
		width: 100%;
		height: 100vh;
		position: fixed;
		padding-top: 40%;
		top: 0;
		left: 0;
		opacity: ${({ show }) => (show ? 1 : 0)};
		visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
		transform: translateY(${({ show }) => (show ? '0' : '-10px')});
		transition: opacity 0.5s ease;
		background-color: #202242;

		font-size: 1.75rem;
	}

	> li:first-child {
		margin-left: auto;
	}
`;

export const NavItem = styled.li`
  height: 80px;
	cursor: pointer;

	&:after {
		content: '';
		position: relative;
		bottom: 2.75rem;
		right: 0;
		width: 0;
		height: 1px;
		display: inline-flex;
		background: #fff;
		justify-content: left;
		transition: all 0.3s ease;
	}

	&:hover:after {
		width: 3rem;
	}

	@media screen and (max-width: 960px) {
		width: 100%;
		&:hover {
			border: none;
		}
	}
`;

export const NavLinks = styled.span`
  color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;

	&:hover {
		transition: all 0.3s ease;
	}

	@media screen and (max-width: 960px) {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;

		&:hover {
			color: #4b59f7;
			transition: all 0.3s ease;
		}
	}
`;

export const NavBtnLink = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 8px 16px;
	height: 100%;
	width: 100%;
	border: none;
	outline: none;
`;