import React, { useRef, useState } from 'react';
import './style.css';
import { Container, Section, Heading, TextWrapper } from '../../globalStyles';
import emailjs from 'emailjs-com';

const Form = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_krv6o84', 'template_nul7pzw', form.current, 'x586pvp9Lia0U_4UM')
      .then((result) => {
        console.log(result.text);
        e.target.reset(); 
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <Section padding="0" smPadding="0" minHeight="fit-content" mdHeight="auto" lgHeight="auto" position="relative" inverse id="prayer">
      <div className="form__bckg">
        <Container>
          <div className="form__container">
            <div className="form__grid">
              <div className="form__col">
                <div className="form__title">
                  <Heading align="left">We are here to <br /><span>pray</span> for you!</Heading>
                </div>
              </div>
              <div className="form__col">
                <form ref={form} onSubmit={sendEmail} className="form__form">
                  <div className="form__form_grid">
                    <input type="text" name='first_name' placeholder='First name *' required />
                    <input type="text" name='last_name' placeholder='Last name' />
                  </div>
                  <div className="form__form_grid">
                    <input type="email" name='email' placeholder='E-mail *' required />
                    <input type="tel" name='phone' placeholder='Phone No. (with country code)' />
                  </div>
                  <div className="form__form_grid">
                    <input type="text" name='country' placeholder='Country *' required />
                    <input type="text" name='zip' placeholder='PIN / ZIP' />
                  </div>
                  <div className="form__form_grid">
                    <input type="text" name='state' placeholder='State *' required />
                    <input type="text" name='city' placeholder='City *' required />
                  </div>
                  <input type="text" name='address_l1' placeholder='Address line 1' />
                  <input type="text" name='address_l2' placeholder='Address line 2' />
                  <div className="form__form_grid">
                    <input type="date" name='date' placeholder='Date (DD/MM/YYYY)' required />
                    <input type="time" name='time' placeholder='Time (HH:MM)' />
                  </div>
                  <textarea type="text" name='purpose' placeholder='Purpose of the request (max 200 characters)' />
                  <div className="form__form_message"><span>* </span> are mandatory fields</div>
                  <div className="form__form_submit">
                    <button type="submit">Request</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Section>
  )
}

export default Form