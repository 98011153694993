export const data = [
	{
		to: '/',
		text: 'About',
		id: 'about',
	},
	{
		to: '/',
		text: 'What we believe',
		id: 'belief',

	},
	{
		to: '/',
		text: 'Prayer',
		id: 'prayer',
	},
	{
		to: '/',
		text: 'Connect',
		id: 'connect',
	},
];
