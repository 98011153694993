import React from 'react';
import Hero from '../components/Hero/Hero';
import Carousel from '../components/Carousel/Carousel';


import About from '../components/About/script';
import Statement from '../components/Statement/script';
import Belief from '../components/Belief/script';

import Form from '../components/Form/script';

// Hero Feature Content Carousel

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Statement />
      <Belief />
      <Form />
      <Carousel />
    </>
  );
}

export default Home;