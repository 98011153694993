import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, MainHeading } from '../../globalStyles';
import { HeroVideo,HeroContent, HeroSection, HeroText, HeroBackground, ButtonWrapper, HeroButton} from './HeroStyle';

const Hero = () => {
  return (
    <HeroSection id="#" mdHeight="auto">
      {/* <HeroVideo src="./assets/Bible.mp4" autoPlay loop muted /> */}
      <HeroBackground src="./assets/hero.jpg" />
      <Container>
        <HeroContent>
          <MainHeading>the Holy Spirit is<br/>our Comforter</MainHeading>
          <HeroText>
            who brings to our rememberance<br/>who Christ is and Christ has done.
          </HeroText>
        </HeroContent>
        {/* <ButtonWrapper>
          <Link to="signup">
            <Button>Get Started</Button>
          </Link>
          <HeroButton>Find More</HeroButton>
        </ButtonWrapper> */}
      </Container>
    </HeroSection>
  )
}

export default Hero;