import React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import GlobalStyle from "./globalStyles";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/script";

// Pages
import Home from "./pages/Home";
// import SignupPage from "./pages/SignupPage";
// import PricingPage from "./pages/PricingPage";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        {/* <Route path="/signup" exact element={<SignupPage />} />
        <Route path="/pricing" exact element={<PricingPage />} /> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
