export const data = [
	{
		// title: 'What our clients say',
		// description: 'Our clients happily stay with our services for more several years now. See real reviews from our clients.',
		image: './assets/carousel-1.jpeg',
	},
	{
		// title: 'Our security ',
		// description: 'Learn more about our security systems to make sure your data is always safe',
		image: './assets/carousel-2.jpeg',
	},
	{
		// title: 'Our Team',
		// description: 'Our team consists of the best experts in the industry, learn about them',
		image: './assets/carousel-3.jpeg',
	},
	{
		// title: 'Our servers',
		// description: 'Find more about hardware and software used for your service',
		image: './assets/carousel-4.jpeg',
	},
	{
		// title: 'Our servers',
		// description: 'Find more about hardware and software used for your service',
		image: './assets/carousel-5.jpeg',
	},
	{
		// title: 'Our servers',
		// description: 'Find more about hardware and software used for your service',
		image: './assets/carousel-6.jpeg',
	},
];

export const sliderSettings = {
	arrows: false,
	slidesToShow: 3,
	focusOnselect: false,
	accessability: true,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			},
		},

		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
