import React, { useState } from 'react';
import { CgClose, CgMenuRight } from 'react-icons/cg';
import { IconContext } from 'react-icons';
import { Nav, NavbarContainer, NavLogo, NavIcon, MobileIcon, NavMenu, NavLinks, NavItem } from './NavbarStyle';
import { useLocation, useNavigate } from 'react-router-dom';
import { data } from '../../data/NavbarData';

const Navbar = () => {

  const [show, setShow] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  const handleClick = () => {
    setShow(!show);
  };

  const scrollTo = (id) => {
    const element = document.getElementById(id);

    element.scrollIntoView({
      behavior: 'smooth',
    });
  }

  const closeMobileMenu = (to, id) => {
    if (id && location.pathname === '/') {
      scrollTo(id);
    }

    navigate(to);
    setShow(false);
  };

  return (
    <IconContext.Provider value={{color: "#fff"}}>
      <Nav>
        <NavbarContainer>
          <NavLogo to='/'>
            <NavIcon src="./assets/logo.svg" alt="logo" />
            Comforter Ministries International
          </NavLogo>
          <MobileIcon onClick={handleClick} >{show ? <CgClose /> : <CgMenuRight />}</MobileIcon>
          <NavMenu show={show}>
            {data.map((el, index) => (
              <NavItem key={index}>
                <NavLinks onClick={() => {closeMobileMenu(el.to, el.id)}} >{ el.text }</NavLinks>
              </NavItem>
            ))}
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  )
}

export default Navbar;