import React from 'react';
import styled from "styled-components";
import './style.css';

const LayoutImage = (props) => {
  return (
    <div className="img__wrapper">
      <img src={props.img} alt={props.alt} width={props.width} />
      {/* <div className="border__box"></div> */}
      <BorderBox inverse={props.inverse}></BorderBox>
    </div>
  )
}

export default LayoutImage;

export const BorderBox = styled.div`
  border: 2px solid #B3B3B2;
  z-index: 2;
  position: absolute;
  top: ${({ inverse }) => (inverse ? "-1rem" : "1rem")};
  left: ${({ inverse }) => (inverse ? "-1rem" : "1rem")};
  width: 100%;
  height: 100%;
  transition: all ease 300ms ;

  &:hover {
    top: 0;
    left: 0;
    border: 4px solid #B3B3B2;
  }
`;