import styled from 'styled-components';
import Slider from 'react-slick';

export const CarouselImage = styled.img`
	width: 100%;
	height: 300px;
	/* border-radius: 10px 10px 0 0; */
	object-fit: cover;
	vertical-align: middle;
`;

export const ImageWrapper = styled.div`
	width: 90%;
	display: flex !important;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	/* border-radius: 10px; */
	outline: none;
	height: auto;

	@media screen and (min-width: 440px) {
		/* border: 1px solid #bebebe; */
	}
`;

export const ReviewSlider = styled(Slider)`
	width: 100%;

	.slick-track {
		display: flex;
		padding: 30px;
		gap: 3rem;
	}
	.slick-slide {
		display: flex;
		justify-content: center;
		margin-bottom: 1;
		outline: none;
	}

	.slick-list {
		overflow: hidden;
	}
`;

export const ControlWrapper = styled.div`
	position: relative;
	width: 100%;
	text-align: right;
`;

export const ButtonContainer = styled.div`
	z-index: 200;
	position: absolute;
	right: 0;
	bottom: 3rem;

	& svg {
		margin: 0 1rem;
		cursor: pointer;
	}

	& svg:hover {
		opacity: 0.7;
		transition: opacity 0.2s ease-in;
	}
	@media screen and (max-width: 1024px) {
		margin: 0 auto;
		visibility: hidden;
		display: none;
	}
`;